import styled from "styled-components";

export const Chip = styled.div`
  display: inline-flex;
  flex-direction: row;
  border: 0.5px solid gray;
  cursor: default;
  color: gray;
  outline: none;
  padding: 2px;
  font-size: 13px;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;

  font-family: NotoSansKR-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #3f444d;
`;

export const ChipHead = styled.div`
  font-weight: bold;
  cursor: inherit;
  white-space: nowrap;
`;

export const ChipContent = styled.div`
  padding: 0 12px;
  cursor: inherit;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const ChipClose = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #999999;
  flex-shrink: 0;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: #fff;
  cursor: pointer;
  font-size: 9px;
  &:hover {
    background-color: #666666;
  }
`;
