import axios from "services/axiosInstance";
import Endpoints from "constants/endpoints";

export async function getAllAuction(formData?: any) {
  return await axios()
    .post(Endpoints.GET_ALL_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function getDetailAuction(params?: any) {
  return await axios()
    .get(Endpoints.GET_DETAIL_AUCTION, { params })
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function createAuction(formData?: any) {
  return await axios()
    .post(Endpoints.ADD_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function updateAuction(formData?: any) {
  return await axios()
    .post(Endpoints.UPDATE_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function updateOnTableAuction(formData?: any) {
  return await axios()
    .post(Endpoints.UPDATE_ON_TABLE_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function updateAuctionMulti(formData?: any) {
  return await axios()
    .post(Endpoints.UPDATE_MULTI_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function getDataSelectLevel2(formData?: any) {
  return await axios()
    .post(Endpoints.FILTER_LEVEL_2, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function deleteAuction(formData?: any) {
  return await axios()
    .post(Endpoints.DELETE_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function calculateAuction(formData?: any) {
  return await axios()
    .post(Endpoints.CALCULATE_AUCTION, formData)
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}

export async function downloadExcelAuction(formData?: any, typeA?: any) {
  return await axios()
    .post(Endpoints.DOWNLOAD_AUCTION, formData, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: typeA,
    })
    .then((response) => {
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}


export async function downloadExcelByFilterForm(formData?: any, typeA?: any) {
  return await axios()
    .post(Endpoints.DOWNLOAD_FILTER_AUCTION, formData, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: typeA,
    })
    .then((response) => {
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response;
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request;
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error;
        // anything else
      }
    });
}