import { Button } from "antd";
import * as S from "./style";

interface Props {
  title?: string;
  contentBtn?: any;
  handleOnClick?: any;
}

function Breadscrum({ title, contentBtn, handleOnClick }: Props) {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      {contentBtn && (
        <S.Btn>
          <Button onClick={handleOnClick}>{contentBtn}</Button>
        </S.Btn>
      )}
    </S.Container>
  );
}

export default Breadscrum;
