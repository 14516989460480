import styled from "styled-components";

export const Container = styled.div`
  tr {
    td {
      &:nth-of-type(2) {
        .editable-cell-value-wrap {
          padding: 0 !important;
        }
      }
    }
  }
  .ant-table-wrapper {
    width: 100%;
    table {
      width: 3000px !important;
    }
  }
  .ant-table-header {
    border-radius: 0 !important;
  }
  .ant-table-thead > tr > th {
    background: #000;
    color: #fff;
    &::before {
      display: none;
    }

    padding: 8px 16px;
  }

  .ant-pagination-item {
    border: none;
    color: #949494;

    &.ant-pagination-item-active {
      color: #fff;
      font-weight: bold;
    }
  }

  .ant-table-wrapper {
    .ant-table-cell {
      font-family: "NotoSansKR-Regular";
      text-align: center;
    }
  }

  .ant-pagination {
    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-prev .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }

    .ant-pagination-item {
      font-family: "NotoSansKR-Regular";
      font-size: 16px;
    }
  }

  .ant-table-body {
    border-left: 1px solid #d9d9d9;

    &::-webkit-scrollbar-thumb {
      background-color: #434343;
    }

    &::-webkit-scrollbar-track {
      background-color: #eee;
    }
  }

  .ant-table-cell-scrollbar {
    background-color: #000 !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid #d9d9d9;
    padding: 2px 12px;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child,
  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-radius: 0;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }

  .ant-pagination .ant-pagination-item-active a {
    color: #886ab5;
  }

  .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background-color: #000;
    opacity: 0.8;
  }

  .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters {
    &:hover {
      background-color: #000;
      opacity: 0.8;

      .ant-table-column-sorter {
        color: #fff;
      }
    }
  }

  .ant-table-wrapper
    .ant-table-thead
    th.ant-table-column-has-sorters.ant-table-cell-fix-left {
    &:hover {
      background-color: #000;
      opacity: 0.8;

      .ant-table-column-sorter {
        color: #fff;
      }
    }
  }

  .ant-table-wrapper .ant-table-column-sorter {
    color: #fff;

    &:hover {
    }
  }
`;

export const PaginationDetail = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 12px 0 0;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-pagination {
    position: relative;
    margin-top: 16px;
    padding-top: 40px;

    .ant-pagination-total-text {
      position: absolute;
      right: 0;
      top: 0;
      min-width: 200px;
      text-align: right;
      font-family: "NotoSansKR-Regular";
    }
  }
`;

export const WrapTopFooter = styled.div`
  display: flex;
  align-items: center;

  .infor-pagination {
    flex-shrink: 0;
    margin-right: 16px;
  }

  .ant-select {
    width: 80px;
  }

  .ant-select-selection-item {
    text-align: left;
  }
`;
