import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .ant-select-auto-complete {
    width: 100% !important;
  }

  .ant-select-selection-search-input {
    font-size: 16px;
  }
`;
