import { Button } from "antd";
import * as S from "./style";

interface Props {
  handleCancel?: () => void;
  handleConfirm?: () => void;
  titlePopup?: string;
  isModalOpen?: boolean;
  content?: any;
}

function PopupAlert(props: Props) {
  const { handleCancel, handleConfirm, isModalOpen, content } = props;

  return (
    <S.Popup
      width={350}
      open={isModalOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} className="btn-cancel">
          취소
        </Button>,
        <Button key="ok" onClick={handleConfirm} className="btn-ok">
          확인
        </Button>,
      ]}
    >
      {content}
    </S.Popup>
  );
}

export default PopupAlert;
