import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import userReducer from "features/user/userSlice";
import themeReducer from "../features/themeToggle/themeSlice";
import history from "utils/history";
import loadingReducer from "features/loading/loadingSlice";

const combinedReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  themeToggle: themeReducer,
  loading: loadingReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "logOut") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
