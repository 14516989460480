import { isEmpty } from "../checkValue";

export const formatArrayToString = (array = [], data = []) => {
  return array.map((val) => (data.includes(val) ? 1 : 0)).join("");
};

export const formatDataFilter = (values: any = {}) => {
  const requestParams = {
    ...values,
    querySearch: values?.querySearch ? values?.querySearch : undefined,
    pricetp: values?.pricetp ? values?.pricetp : undefined,
    materialSearch: values?.materialSearch ? values?.materialSearch : undefined,
    title: values?.title ? values?.title : undefined,
    auctionName: values?.auctionName ? values?.auctionName : undefined,
    bidClass: values?.bidClass
      ? values?.bidClass.map((item) =>
          item === "W/D" ? item.toLowerCase() : item
        )
      : undefined,
    price: !isEmpty(values?.price) ? values?.price : undefined,
    material: values?.material?.length > 0 ? values?.material : undefined,
    transactDate: !isEmpty(values?.transactDate)
      ? values?.transactDate
      : undefined,
    mfgDate: !isEmpty(values?.mfgDate) ? values?.mfgDate : undefined,
    height: !isEmpty(values?.height) ? values?.height : undefined,
    width: !isEmpty(values?.width) ? values?.width : undefined,
    sizeTable: !isEmpty(values?.sizeTable) ? values?.sizeTable : undefined,
    company: values?.company?.length > 0 ? values?.company : undefined,
    // location: values?.location?.length > 0 ? values?.location : undefined,
    onOff: values?.onOff ? values.onOff : undefined,
    certification: values?.certification
      ? Number(values.certification)
      : undefined,
    order: values?.order ?? undefined,
    field: values?.field ?? undefined,
    method: values?.method?.length > 0 ? values?.method : undefined,
    mainColor: values?.mainColor?.length > 0 ? values?.mainColor : undefined,
    series: values?.series?.length > 0 ? values?.series : undefined,
    preference: values?.preference?.length > 0 ? values?.preference : undefined
  };

  return requestParams;
};
