import React, { useEffect } from "react";
import ImageUploading from "react-images-uploading";
import * as S from "./style";
// import { singleUpload } from "services/Upload/index";
import { useTranslation } from "react-i18next";
// import AddIcon from '@material-ui/icons/Add';
// import CloseIcon from '@material-ui/icons/Close';

interface Props {
  handleGetImages?: (e) => void;
  numberItemUpload?: number;
  initValues?: Array<string>;
  typeUpload?: string;
  typepage?: string;
  isRemove?: boolean;
}

function ImagesUpload(props: Props) {
  const {
    handleGetImages,
    numberItemUpload,
    initValues,
    typeUpload,
    typepage,
    isRemove,
  } = props;
  const { t } = useTranslation("common");
  const [images, setImages] = React.useState([]);
  // console.log("images:", images)
  useEffect(() => {
    // console.log("init 1 lan thoi nhe");
    setImages(
      initValues?.map((e) => {
        // console.log("initValues", e);

        return { data_url: e, do_upload: true };
      }) ?? []
    );
    // console.log("Array init values:", initValues)
  }, [initValues]);
  // console.log("images init values:", images)
  const maxNumber = numberItemUpload || 100;
  // const [listAfterUpload, setListAfterUpload] = React.useState([]);

  // console.log("setListAfterUpload", listAfterUpload);

  // const handleUploadImage = async (formData) => {
  //   let resp = await singleUpload(formData)
  //   console.log("images", images);
  //   renderImageData(resp.Location)
  // }

  // const renderImageData = (imgSrc: string) => {
  //   listAfterUpload.push(imgSrc);
  //   handleGetImages(listAfterUpload)
  //   setListAfterUpload([])
  // }

  const onChange = async (imageList, addUpdateIndex) => {
    // console.log("imageList", imageList, addUpdateIndex);
    handleGetImages(imageList);
    setImages(imageList);
    // const listDataImg = imageList && imageList.map((item, index) => {
    //   const formData = new FormData()
    //   formData.append('file', item.file)
    //   return(
    //     handleUploadImage(formData)
    //   )
    // })
    // console.log("imageList", imageList);
  };

  const handleError = (e) => {
    console.log("errror", e);

    if (e.maxNumber) {
      alert(`Cannot upload more than ${numberItemUpload} images`);
    }
  };

  return (
    <S.Container>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        onError={(e) => handleError(e)}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className="recomended-size">
              {t("UPLOAD_NEW_IMAGE")}{" "}
              {typeUpload === "singleUpload"
                ? ""
                : `(${t("RECOMMENDED_SIZE")}) `}
              :
            </div>
            <div className="list-render-img">
              {typepage !== "update" && (
                <div className="image-item">
                  <button
                    className="add-img"
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={(e) => {
                      onImageUpload();
                      console.log("123123123", e);
                    }}
                    {...dragProps}
                  >
                    {/* <AddIcon fontSize="large" /> */}
                  </button>
                </div>
              )}
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <div className="image-item-wrap">
                    <img
                      className={`${index === 0 && "main"}`}
                      src={image["data_url"]}
                      alt=""
                    />
                    <div className="image-item__btn-wrapper">
                      {index === 0 && typeUpload !== "singleUpload" && (
                        <button className="main-img">
                          {t("UPLOAD_MAIN_IMAGE")}
                        </button>
                      )}
                      <button
                        className="img-btn btn-update"
                        onClick={() => {
                          onImageUpdate(index);
                          // console.log("index =>>>>>>>>>>>", index);
                        }}
                      >
                        Update
                      </button>
                      {isRemove && (
                        <button
                          className="img-btn btn-remove"
                          onClick={() => {
                            onImageRemove(index);
                          }}
                        >
                          {/* <CloseIcon/> */}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {images.length > 0 && typeUpload !== "singleUpload" && (
              <button className="remove-all" onClick={onImageRemoveAll}>
                Remove all images
              </button>
            )}
          </div>
        )}
      </ImageUploading>
    </S.Container>
  );
}

export default ImagesUpload;
