import queryString from "query-string";
/**
 * Add query parameters to URL
 * Remove query parameter in URL if value is empty
 * @param {object} router - router object
 * @param {string} paramName- query parameter name
 * @returns {void}
 */
export const addParamUrl = (history, requestURL) => {
  const paramKeys = Object.keys(requestURL);
  const paramValues = Object.values(requestURL);
  let currentRouterQuery: any = queryString.parse(history.location.search);

  paramKeys.forEach((key, index) => {
    // if (key === "field" || key === "order") {
    //   return;
    // }
    paramValues[index]
      ? (currentRouterQuery[key] = paramValues[index])
      : delete currentRouterQuery[key];
  });

  history.replace({
    pathname: history.location.pathname,
    search: queryString.stringify(currentRouterQuery),
  });
};
/**
 * Get value of URL parameter
 * @param {object} router - router object
 * @param {string} paramName- query parameter name
 * @returns {string}
 */
export const getParamUrlValue = (location, paramName) => {
  const query = queryString.parse(location?.search);
  return query[paramName]?.length > 0 ? query[paramName] : undefined;
};
