import { AuditOutlined, FundOutlined, UserOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import Routes from "constants/routes";
import { logOut } from "features/user/userSlice";
import { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as S from "./style";
import { RootState } from "redux/reducer";

interface Props {
  sideBarMenu?: any;
  open?: boolean;
  collapsed?: boolean;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const menuList = [
  getItem(
    <Link to={Routes.LIST_AUCTION_DATA}>경매 데이터 관리</Link>,
    Routes.AUCTION_DATA,
    <FundOutlined />
  ),
  getItem(
    <Link to={Routes.LIST_AUTHOR_BIO}>작가 바이오 관리</Link>,
    Routes.AUTHOR_BIO,
    <AuditOutlined />
  ),
];

function SideBar({ collapsed }: Props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { authorName }: any = useSelector((state: RootState) => state.user);
  const [pathName, setPathName] = useState(window.location.href.split("/")[3]);

  useEffect(() => {
    const href = window.location.href.split("/")[3];
    setPathName(href);
  }, [location?.pathname]);

  const handleOnClick = () => {
    dispatch(logOut());
  };
  return (
    <S.Sidebar>
      <S.SidebarHead>
        <p>Welcome</p>
        {collapsed ? (
          <S.IconUserName>
            <div className="icon-round">
              <UserOutlined />
            </div>
          </S.IconUserName>
        ) : (
          <S.Username>{authorName}</S.Username>
        )}
      </S.SidebarHead>
      <S.SidebarBody>
        <Menu mode="inline" items={menuList} selectedKeys={["/" + pathName]} />
      </S.SidebarBody>
      <S.SidebarFooter>
        <div onClick={handleOnClick}>로그아웃</div>
      </S.SidebarFooter>
    </S.Sidebar>
  );
}

export default SideBar;
