const Fonts = {};

const Themes = {
  default: {},
};

const lightTheme = {
  primary: "#584475",
  secondary: "",
  background: "#fff",
  textPrimary: "#fff",
  textSecondary: "#212529",
};
const darkTheme = {
  primary: "#584475",
  secondary: "",
  background: "#fff",
  textPrimary: "#fff",
  textSecondary: "#212529",
};

export { Fonts, Themes, lightTheme, darkTheme };
