export const SUCCESS = ["SOLD", "NOTSOLD", "WITHDRAW"];
export const BID_CLASS = ["ABOVE", "WITHIN", "BELOW", "UNSOLD", "W/D"];
export const MATERIAL = [
  "Painting",
  "Works on Paper",
  "Edition",
  "Sculpture",
  "Decorative Art",
  "Photo",
  "Multi-media",
  "Books&Manuscripts",
  "Furniture&Design",
  "Watch&Jewelry&Handbags",
  "Wine&Whisky&Sprits",
  "Others"
];
export const AUC_COM = [
  "아이옥션",
  "칸옥션",
  "케이옥션",
  "서울옥션",
  "아트데이옥션",
  "에이옥션",
  "마이아트옥션",
  "라이즈아트옥션",
  "토탈아트옥션",
  "Phillips",
  "Sothebys",
  "Christies",
];
export const ON_OFF_LIST = [
  { label: "On-Line", value: "online" },
  { label: "Off-Line", value: "offline" },
];
export const ON_OFF_MAP = {
  online: "On-Line",
  offline: "Off-Line",
};
export const CERTIFICATION_LIST = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];
export const CERTIFICATION_MAP = {
  0: "No",
  1: "Yes",
};
export const LOCAL = ["국내", "해외"];

export const NAME_FILTERS = {
  sellOrNot: "판매여부",
  bidClass: "경매결과 구분",
  pricetp: "가격 종류",
  price: "가격",
  material: "재료별",
  materialSearch: "재료별 검색",
  transactDate: "거래일",
  mfgDate: "제작연도",
  height: "세로",
  width: "가로",
  sizeTable: "호수",
  title: "제목",
  company: "옥션사",
  location: "지역",
  onOff: "온/오프",
  certification: "지역",
  auctionName: "경매명",
  method: "Method",
  mainColor: "Main color",
  series: "Series",
  preference: "Preference"
};
export const PRICE_MAP = {
  hammer_price: "낙찰가",
  selling_price: "판매가",
  estimate_min: "낮은추정가",
  estimate_max: "높은추정가",
};
export const LOCATION = [
  "Korea",
  "Geneva",
  "Hong Kong",
  "London",
  "New York",
  "Paris",
  "Singapore",
];
export const DATA_COLORS = [
  {value: "검은색"}, //BLACK
  {value: "흰색"}, //WHITE
  {value: "빨간색"}, //RED
  {value: "파랑"}, //BLUE
  {value: "녹색"}, //GREEN
  {value: "보라색"}, //PURPLE
  {value: "회색"}, //GREY
  {value: "노란색"}, //YELLOW
  {value: "주황색"}, //ORANGE
  {value: "베이지색"}, //BEIGE
  {value: "핑크"}, //PINK
  {value: "골드"}, //GOLD
  {value: "갈색"}, //BROWN
];

export const dataMethods = [
  // "ABOVE", 
  // "WITHIN", 
  "구상", 
  "반추상", 
  "추상"
];