export const dataLocation = [
  { label: "Korea", value: "Korea" },
  { label: "New York", value: "New York" },
  { label: "London", value: "London" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Paris", value: "Paris" },
  { label: "Geneva", value: "Geneva" },
  { label: "Amsterdam", value: "Amsterdam" },
  { label: "Aspen", value: "Aspen" },
  { label: "Beaune", value: "Beaune" },
  { label: "Beijing", value: "Beijing" },
  { label: "Bordeaux", value: "Bordeaux" },
  { label: "Brussels", value: "Brussels" },
  { label: "Chatsworth", value: "Chatsworth" },
  { label: "Chicago", value: "Chicago" },
  { label: "Cologne", value: "Cologne" },
  { label: "Derbyshire", value: "Derbyshire" },
  { label: "Doha", value: "Doha" },
  { label: "Dubai", value: "Dubai" },
  { label: "East Hampton", value: "East Hampton" },
  { label: "Edinburgh", value: "Edinburgh" },
  { label: "Essen", value: "Essen" },
  { label: "Hampton", value: "Hampton" },
  { label: "Hershey", value: "Hershey" },
  { label: "Lake Como", value: "Lake Como" },
  { label: "Las Vegas", value: "Las Vegas" },
  { label: "Los Angeles", value: "Los Angeles" },
  { label: "Madrid", value: "Madrid" },
  { label: "Miami", value: "Miami" },
  { label: "Milan", value: "Milan" },
  { label: "Monaco", value: "Monaco" },
  { label: "Montauk", value: "Montauk" },
  { label: "Monte Carlo", value: "Monte Carlo" },
  { label: "Monterey", value: "Monterey" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Munich", value: "Munich" },
  { label: "Napa", value: "Napa" },
  { label: "Palm Beach", value: "Palm Beach" },
  { label: "San Francisco", value: "San Francisco" },
  { label: "Santa Monica", value: "Santa Monica" },
  { label: "Singapore", value: "Singapore" },
  { label: "Tel Aviv", value: "Tel Aviv" },
  { label: "Toronto", value: "Toronto" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Washington DC", value: "Washington DC" },
  { label: "Baltimore", value: "Baltimore" },
  { label: "Wiltshire", value: "Wiltshire" },
  { label: "Zurich", value: "Zurich" },
];

export const dataMaterial = [
  { label: "Watch&Jewelry&Handbags", value: "Watch&Jewelry&Handbags" },
  { label: "Furniture&Design", value: "Furniture&Design" },
  { label: "Collectables", value: "Collectables" },
  { label: "America Art", value: "America Art" },
  { label: "Asia Art", value: "Asia Art" },
  { label: "Middle/Central Asia Art", value: "Middle/Central Asia Art" },
  { label: "Oceania Art", value: "Oceania Art" },
  { label: "Europe Art", value: "Europe Art" },
  { label: "Africa Art", value: "Africa Art" },
  { label: "Others", value: "Others" },
  { label: "NFT", value: "NFT" },
  { label: "Africa/Oceania/Latin Art", value: "Africa/Oceania/Latin Art" },
  { label: "Edition", value: "Edition" },
  { label: "Decorative Art", value: "Decorative Art" },
  { label: "Sculpture", value: "Sculpture" },
  { label: "Works on Paper", value: "Works on Paper" },
  { label: "Painting", value: "Painting" },
  { label: "Multi-media", value: "Multi-media" },
  { label: "Photo", value: "Photo" },
];

export const dataNational = [
  { label: "American", value: "American" },
  { label: "Argentine", value: "Argentine" },
  { label: "Argentinian", value: "Argentinian" },
  { label: "Australian", value: "Australian" },
  { label: "Austrian", value: "Austrian" },
  { label: "Belgian", value: "Belgian" },
  { label: "Brazilian", value: "Brazilian" },
  { label: "British", value: "British" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Cameroonian", value: "Cameroonian" },
  { label: "Canadian", value: "Canadian" },
  { label: "Chinese", value: "Chinese" },
  { label: "Cuban", value: "Cuban" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Dominican", value: "Dominican" },
  { label: "Dutch", value: "Dutch" },
  { label: "Filipino", value: "Filipino" },
  { label: "French", value: "French" },
  { label: "German", value: "German" },
  { label: "Ghanaian", value: "Ghanaian" },
  { label: "Haitian", value: "Haitian" },
  { label: "Hong Konger", value: "Hong Konger" },
  { label: "HongKong", value: "HongKong" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Icelandic", value: "Icelandic" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Irish", value: "Irish" },
  { label: "Israeli", value: "Israeli" },
  { label: "Italian", value: "Italian" },
  { label: "Ivorian", value: "Ivorian" },
  { label: "Japanese", value: "Japanese" },
  { label: "Korean", value: "Korean" },
  { label: "Lebanese", value: "Lebanese" },
  { label: "Lithuanian", value: "Lithuanian" },
  { label: "New Zealander", value: "New Zealander" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Philippine", value: "Philippine" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Puerto Rican", value: "Puerto Rican" },
  { label: "Russian", value: "Russian" },
  { label: "Spanish", value: "Spanish" },
  { label: "Swedish", value: "Swedish" },
  { label: "Swiss", value: "Swiss" },
  { label: "Thai", value: "Thai" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Uruguayan", value: "Uruguayan" },
  { label: "Venezuelan", value: "Venezuelan" },
  { label: "Vietnamese", value: "Vietnamese" },
];

export const dataPreferences = [
  "상", // Cao
  "중", // Trung
  "하", // Thấp
];