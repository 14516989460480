import React from "react";
import { InputNumber, Space, Select } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { PRICE_MAP } from "constants/filtersName";
import { useFormContext, useWatch } from "react-hook-form";
import * as S from "./style";

const { Option } = Select;

const SelectMinMax = ({ pricept = false, name }) => {
  const { control, setValue, getValues } = useFormContext();
  const fieldValue = getValues(name) || [];
  const pricetpField = useWatch({
    control,
    name: "pricetp",
  });

  const [valuePricetp, setValuePricetp] = useState(pricetpField);

  const [min, setMin] = useState(fieldValue[0] ?? "");
  const [max, setMax] = useState(fieldValue[1] ?? "");
  //used reset value for field ${name} (name used common)
  useEffect(() => {
    if (min === "" && max === "") {
      return;
    }
    if (!min && !max) {
      return setValue(`${name}`, undefined);
    }
    if (max > 0 && min > 0 && Number(max) < Number(min)) {
      return;
    }

    setValue(`${name}`, [min ?? "", Number(max) >= Number(min) ? max : ""], {
      shouldDirty: true,
    });
  }, [min, max]);

  useEffect(() => {
    if (!getValues(name)) {
      setMin(undefined);
      setMax(undefined);
    }
  }, [JSON.stringify(getValues(name))]);
  //used reset value for field pricetp
  useEffect(() => {
    if (!pricetpField) {
      setValuePricetp(undefined);
    }
  }, [JSON.stringify(pricetpField)]);

  useEffect(() => {
    if (!valuePricetp) {
      return setValue("pricetp", undefined);
    }
    setValue("pricetp", valuePricetp);
  }, [valuePricetp]);

  return (
    <S.WrapContent>
      <Space>
        {pricept && (
          <Select
            value={valuePricetp}
            onChange={(value) => setValuePricetp(value)}
            size="small"
            allowClear
            placeholder="가격 종류"
            style={{
              minWidth: 150,
            }}
          >
            {Object.keys(PRICE_MAP).map((price, index) => (
              <Option key={index} value={price}>
                {PRICE_MAP[price]}
              </Option>
            ))}
          </Select>
        )}
        <InputNumber
          // size="small"
          placeholder="MIN"
          value={min}
          onChange={(value) => setMin(value)}
          type="number"
        />
        <span>~</span>
        <InputNumber
          // size="small"
          placeholder="MAX"
          value={max}
          onChange={(value) => setMax(value)}
          type="number"
        />
      </Space>
    </S.WrapContent>
  );
};

export default SelectMinMax;
