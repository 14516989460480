import axios from "services/axiosInstance";
import Endpoints from "constants/endpoints";

export interface User {
  // userid: string,
  authorName: string;
  accessToken: string;
  // email: string,
}

export interface UserResult {
  success: boolean | null;
  data: User;
  msgCode: string | null;
  errors: object | null;
}

export async function userLogin(formData: object): Promise<UserResult> {
  try {
    const response = await axios().post(Endpoints.LOGIN, formData);
    return {
      success: response["result"],
      data: {
        accessToken: response.data["accessToken"],
        authorName: response.data["displayName"],
      },
      msgCode: response["message"],
      errors: response["message"],
    };
  } catch (error) {
    throw error;
  }
}

export async function getMe(params?: any) {
  return await axios()
    .get(Endpoints.GET_ME, { params })
    .then((response) => {
      // console.log("response", response);
      return response;
    })
    // 응답(실패)
    .catch((error) => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("error", error);
        return error;
      }
    });
}
