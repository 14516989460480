import {
  AutoComplete,
  DatePicker,
  Form,
  Image,
  Input,
  InputRef,
  Pagination,
  Space,
  Table,
} from "antd";
import type { FormInstance } from "antd/es/form";
import noImage from "assets/images/noImage.jpg";
import Routes from "constants/routes";
import dayjs from "dayjs";
import { DataType, EditableCellProps, EditableRowProps } from "models/table";
import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MoneyConvert, checkSort } from "utils/unit";
import * as S from "./style";
import { DATA_COLORS } from "constants/filtersName";
import NoImage from "../../assets/images/noimg.jpeg";
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Props {
  datas: any;
  columns?: any;
  pagination?: any;
  load?: boolean;
  height?: string | number;
  onShowSizeChange?: any;
  handleOnEdit?: any;
  getSelectedRow?: any;
  setDataTable?: any;
  selectedRowKeys?: any;
  setSelectedRowKeys?: any;
  onChangeTable?: any;
  sorter?: any;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleEdit,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const inputRef1 = useRef<InputRef>(null);
  const inputRef2 = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const [transactDate, setTransactDate] = useState(
    record ? record["transactDate"] : new Date()
  );
  // useEffect(() => {
  //   if (dataIndex === "transactDate") return;
  //   if (editing) {
  //     inputRef.current!.focus();
  //   }
  // }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const renderItemForm = () => {
    let type = "text";
    if (
      dataIndex === "height" ||
      dataIndex === "width" ||
      dataIndex === "depth" ||
      dataIndex === "sizeTable" ||
      dataIndex === "mfgDate" ||
      dataIndex === "identicalRecords"
    ) {
      type = "number";
    } else if (dataIndex === "title") {
      return (
        <Space direction="vertical">
          <Form.Item
            style={{ margin: 0 }}
            name={"titleKor"}
            initialValue={record["titleKor"]}
          >
            <Input ref={inputRef1} onPressEnter={save} onBlur={save} />
          </Form.Item>
          <Form.Item
            style={{ margin: 0 }}
            name={"titleEng"}
            initialValue={record["titleEng"]}
          >
            <Input ref={inputRef2} onPressEnter={save} onBlur={save} />
          </Form.Item>
        </Space>
      );
    } else if (dataIndex === "artistYear") {
      return (
        <Space direction="vertical">
          <Form.Item
            style={{ margin: 0 }}
            name={"artistBirth"}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
              () => ({
                validator(rule, value, callback) {
                  if (value < 0) {
                    return Promise.reject("입력한 숫자가 0보다 커야 합니다.");
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            initialValue={record["artistBirth"]}
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              type="number"
              onBlur={save}
            />
          </Form.Item>
          <Form.Item
            style={{ margin: 0 }}
            name={"artistDeath"}
            initialValue={
              record["artistDeath"] === 0 || record["artistDeath"] === null
                ? ""
                : record["artistDeath"]
            }
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              type="number"
              onBlur={save}
            />
          </Form.Item>
        </Space>
      );
    } else if (dataIndex === "transactDate") {
      return (
        <DatePicker
          onBlur={save}
          defaultValue={dayjs(record["transactDate"])}
          onChange={(date: any, dateString: string) =>
            setTransactDate(dateString)
          }
        />
      );
    } else if (dataIndex === "mainColor") {
      return (
        <Form.Item name="mainColor" initialValue={record["mainColor"]}>
          <AutoComplete
            options={DATA_COLORS}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
            />
          </AutoComplete>
        </Form.Item>
      )
    }
    return (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        //   ({ getFieldValue }) => ({
        //     validator(rule, value, callback) {
        //       if (type === "number" && value < 0) {
        //         return Promise.reject("입력한 숫자가 0보다 커야 합니다.");
        //       } else {
        //         return Promise.resolve();
        //       }
        //     },
        //   }),
        // ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          type={type}
          min={0}
        />
      </Form.Item>
    );
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
        transactDate: transactDate,
      });
      handleEdit({
        id: record.id,
        ...values,
        transactDate: transactDate,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      renderItemForm()
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onDoubleClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
/////////////////////////////////////////////////////////////////
type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const TableEdit = (props: Props) => {
  const {
    datas,
    pagination,
    load,
    height,
    handleOnEdit,
    getSelectedRow,
    setDataTable,
    selectedRowKeys,
    setSelectedRowKeys,
    onChangeTable,
    sorter,
  } = props;
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      getSelectedRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text: any) => (
        <Link
          to={Routes.PRE_DETAIL_AUCTION_DATA + "/" + text}
          className="link"
          target="_blank"
        >
          {text}
        </Link>
      ),
      fixed: "left",
      width: "5%",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "transact_date" ? checkSort(sorter?.order) : null,
    },
    {
      title: "출품처",
      dataIndex: "company",
      key: "company",
      editable: true,
      width: "8%",
      fixed: "left",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "company" ? checkSort(sorter?.order) : null,
    },
    {
      title: "거래일",
      dataIndex: "transactDate",
      key: "transactDate",
      render: (text: any) => moment(text).format("YYYY-MM-DD"),
      width: "9%",
      editable: true,
      fixed: "left",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "transact_date" ? checkSort(sorter?.order) : null,
    },
    {
      title: "이미지",
      dataIndex: "img",
      key: "img",
      render: (text: any) => {
        let urlImage = "";
        if (JSON.stringify(text).includes("[")) {
          const arrImg = JSON.parse(text);
          if (!arrImg || arrImg.length <= 0) {
            urlImage = noImage;
          } else urlImage = arrImg[0].image_url;
        } else if (text === "") {
          urlImage = noImage;
        } else {
          urlImage = text;
        }

        return <Image 
          src={urlImage} 
          onError={(event:any) => {
            event.target.src = NoImage;
            event.onerror = null;
          }} 
          className="image-table" />;
      },
      width: "10%",
      fixed: "left",
    },
    {
      title: "Lot No.",
      dataIndex: "lot",
      key: "lot",
      width: "6%",
      fixed: "left",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "lot" ? checkSort(sorter?.order) : null,
    },
    {
      title: "작가명",
      dataIndex: "authorName",
      key: "authorName",
      render: (_: any, record: any) => {
        return (
          <div>
            <p>{record.artistKor}</p>
            <p>{record.artistEng}</p>
          </div>
        );
      },
      sorter: (a: any, b: any) => null,
      width: "8%",
      fixed: "left",
      defaultSortOrder:
        sorter?.field === "artist_kor" ? checkSort(sorter?.order) : null,
    },
    // {
    //   title: "작가생몰년",
    //   dataIndex: "artistYear",
    //   key: "artistYear",
    //   render: (_: any, record: any) => {
    //     return (
    //       (record.artistBirth && record.artistBirth !== 0
    //         ? record.artistBirth
    //         : "") +
    //       " - " +
    //       (record.artistDeath && record.artistDeath !== 0
    //         ? record.artistDeath
    //         : "")
    //     );
    //   },
    //   width: "12%",
    //   editable: true,
    // },
    {
      title: "작품명",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: any) => (
        <div>
          <p>{record.titleKor}</p>
          <p>{record.titleEng}</p>
        </div>
      ),
      width: "8%",
      editable: true,
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "title_kor" ? checkSort(sorter?.order) : null,
    },
    {
      title: "제작년도",
      dataIndex: "mfgDate",
      key: "mfgDate",
      width: "8%",
      editable: true,
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "mfg_date" ? checkSort(sorter?.order) : null,
    },
    {
      title: "재료별",
      dataIndex: "materialKind",
      key: "materialKind",
      sorter: (a: any, b: any) => null,
      width: "8%",
      defaultSortOrder:
        sorter?.field === "material_kind" ? checkSort(sorter?.order) : null,
    },
    {
      title: "재료명",
      dataIndex: "materialName",
      key: "materialName",
      width: "8%",
      render: (_: any, record: any) => {
        return (
          <div>
            <p>{record.materialKor}</p>
            <p>{record.materialEng}</p>
          </div>
        );
      },
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "material_kor" ? checkSort(sorter?.order) : null,
    },
    {
      title: "세로",
      dataIndex: "height",
      key: "height",
      width: "8%",
      editable: true,
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "height" ? checkSort(sorter?.order) : null,
    },
    {
      title: "가로",
      dataIndex: "width",
      key: "width",
      width: "7%",
      editable: true,
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "width" ? checkSort(sorter?.order) : null,
    },
    {
      title: "깊이",
      dataIndex: "depth",
      key: "depth",
      width: "7%",
      editable: true,
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "depth" ? checkSort(sorter?.order) : null,
    },
    {
      title: "호수",
      dataIndex: "sizeTable",
      key: "sizeTable",
      width: "7%",
      editable: true,
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "size_table" ? checkSort(sorter?.order) : null,
    },
    {
      title: "낙찰가",
      dataIndex: "hammerPrice",
      key: "hammerPrice",
      render: (text: any) => (text ? MoneyConvert(text) : ""),
      width: "7%",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "hammer_price" ? checkSort(sorter?.order) : null,
    },
    {
      title: "낮은추정가",
      dataIndex: "estimateMin",
      key: "estimateMin",
      render: (text: any) => (text ? MoneyConvert(text) : ""),
      width: "7%",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "estimate_min" ? checkSort(sorter?.order) : null,
    },
    {
      title: "높은추정가",
      dataIndex: "estimateMax",
      key: "estimateMax",
      render: (text: any) => (text ? MoneyConvert(text) : ""),
      width: "7%",
      sorter: (a: any, b: any) => null,
      defaultSortOrder:
        sorter?.field === "estimate_max" ? checkSort(sorter?.order) : null,
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      width: "7%",
      editable: true,
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      width: "7%",
      editable: true,
    },
    {
      title: "Main Color",
      dataIndex: "mainColor",
      key: "mainColor",
      width: "7%",
      editable: true,
    },
    {
      title: "Preference",
      dataIndex: "preference",
      key: "preference",
      width: "7%",
      editable: true,
    },
    {
      title: "Identical Record",
      dataIndex: "identicalRecords",
      key: "identicalRecords",
      render: (text: any) => {
        if (!text || text === "[]") return "";
        else {
          return text;
        }
      },
      width: "7%",
      editable: true,
    },
  ];

  const handleSave = (row: DataType) => {
    const newData = [...datas];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataTable(newData);
  };

  const handleEdit = (obj) => {
    handleOnEdit?.(obj);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        handleEdit,
      }),
    };
  });

  return (
    <S.Container>
      <Table
        components={components}
        bordered
        dataSource={datas}
        columns={columns as ColumnTypes}
        scroll={{ y: height || 960, x: 1900 }}
        rowKey={(record: any) => {
          return record.key ? record.key : record.id;
        }}
        loading={load}
        {...props}
        pagination={false}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        onChange={onChangeTable}
      />
      {pagination?.total !== null && pagination?.total > 0 && (
        <>
          <S.Pagination>
            <Pagination
              {...pagination}
              showTotal={(total, range) =>
                `Row: ${range[0]} - ${range[1]} / total: ${total}`
              }
              showSizeChanger={true}
              pageSizeOptions={[20, 50, 100, 200, 300, 400, 500]}
            />
          </S.Pagination>
        </>
      )}
    </S.Container>
  );
};

export default TableEdit;
