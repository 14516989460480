import { BarsOutlined } from "@ant-design/icons";
import LogoImg from "assets/images/logo.png";
import * as S from "./style";

interface Props {
  isOpen?: boolean;
  userInfo?: any;
  handleDrawerOpen?: any;
}

function Header({ handleDrawerOpen }: Props) {
  return (
    <S.Header>
      <span>
        <BarsOutlined className="trigger" onClick={handleDrawerOpen} />
        <img src={LogoImg} className={"logo"} alt="logo.png" />
      </span>
    </S.Header>
  );
}

export default Header;
