import { ENV } from "configs/constraints";
export default function ApiConfig() {
  const configs = {
    development: {
      // BASE_URL: `http://192.168.1.95:3000/api/v1`,
      // BASE_URL: `https://169a-2405-4803-fbe5-b30-49b5-582-5270-5d55.ngrok.io/api/v1`
      //BASE_URL: `https://api.artnresearch.com/api/v1`,
      // BASE_URL: `https://dev-api.artnresearch.com/api/v1`, //dev
      BASE_URL: `https://api.artnresearch.com/api/v1`, //main
      //BASE_URL: `http://192.168.1.95:3000/api/v1`,
    },
    staging: {
      BASE_URL: process.env.REACT_APP_URL_BE,
    },
    production: {
      BASE_URL: process.env.REACT_APP_URL_BE,
    },
  };

  return configs[ENV];
}
