import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Input } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerInput, SpaceStyle } from "./style";
import * as S from "./style";
const CheckboxRender = ({ options = [], inputSearch = false, name }) => {
  const { control } = useFormContext();

  return (
    <SpaceStyle direction="vertical">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <S.CheckBox>
            <S.CheckBoxGroup
              {...field}
              options={options}
              style={{
                minWidth: 150,
                // maxWidth: 180,
                maxHeight: 500,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            />
          </S.CheckBox>
        )}
      />

      {inputSearch && (
        <Controller
          name="materialSearch"
          control={control}
          render={({ field }) => (
            <ContainerInput>
              <Input
                {...field}
                size="small"
                placeholder="재료명 입력"
                id="materialSearch"
              />
              <SearchOutlined className="icon-search" />
            </ContainerInput>
          )}
        />
      )}
    </SpaceStyle>
  );
};

export default CheckboxRender;
