import React from "react";

import * as S from "./style";

interface Props {
  isOverlay?: boolean;
  isLoading?: boolean;
}

function LoadSpinner(props: Props): React.ReactElement {
  const { isOverlay, isLoading } = props;
  return (
    <>
      {isLoading && (
        <S.LoadSpinner>
          <div className="spinner-content">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {isOverlay && <div className="overlay"></div>}
        </S.LoadSpinner>
      )}
    </>
  );
}

export default LoadSpinner;
