import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import * as S from "./style";

interface Props {
  content: any;
}

const { Panel } = Collapse;

function CollapseBox({ content }: Props) {
  return (
    <S.Container>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            className="icon-arrow"
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel header="More Info" key="1">
          {content}
        </Panel>
      </Collapse>
    </S.Container>
  );
}

export default CollapseBox;
