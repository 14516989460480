import { Radio } from "antd";
import * as S from "./style";

interface Props {
  value?: any;
  defaultValue?: any;
  list?: any;
  onChange?: any;
}

function RadioGroup({ list, value, onChange, defaultValue }: Props) {
  return (
    <S.Container>
      <Radio.Group
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      >
        {list.map((item: any, index: any) => (
          <Radio value={item.value} key={index}>
            {item.label}
          </Radio>
        ))}
      </Radio.Group>
    </S.Container>
  );
}

export default RadioGroup;
