import styled from "styled-components";

export const Container = styled.div`
  .ant-collapse {
    border-radius: 0;
  }
  .ant-collapse-header {
    flex-direction: row-reverse;
    align-items: center !important;
    border-radius: 0 !important;
    padding-left: 24px !important;
    background-color: #ebf1ff;
  }

  .ant-collapse-header-text {
    font-family: "NotoSansKR-Regular";
    font-size: 18px;
    font-weight: bold;
  }

  .icon-arrow {
    font-size: 18px !important;
  }

  .ant-collapse-item {
    background-color: #fff;
    border-radius: 0 !important;
  }

  .ant-collapse-content {
    border-radius: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;
