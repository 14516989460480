import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;

  .header {
    background-color: #fff;
    box-shadow: 0 0 28px 0 rgba(86, 61, 124, 0.13);
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .sider {
    background-color: #584475;
  }

  .ant-layout {
    height: 100%;
    background-color: #fff;
  }

  .ant-layout-header {
    padding: 8px 24px;
    min-height: 64px;
    height: auto;
  }

  .content {
    padding: 24px 32px;
    background-color: #faf8fb;
    overflow-y: auto;
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(62, 44, 90, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 4px;
`;
