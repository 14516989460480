import { RootState } from "redux/reducer";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { LoadSpinner, Toastify } from "components";
import Routes from "constants/routes";
import useUser from "hooks/useUser";
import { DefaultLayout } from "layouts";
import GlobalStyles from "./global-styles";
import { darkTheme, lightTheme } from "configs/theme";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

const LoginPage = lazy(() => import("pages/LoginPage"));
const AuctionDataManagement = lazy(() => import("pages/AuctionDataManagement"));
const CreateAuctionData = lazy(
  () => import("pages/AuctionDataManagement/CreateAuctionData")
);
const AuctionDataDetail = lazy(
  () => import("pages/AuctionDataManagement/AuctionDataDetail")
);
const AuthorBioManagement = lazy(() => import("pages/AuthorBioManagement"));
const CreateAuthorBio = lazy(
  () => import("pages/AuthorBioManagement/CreateAuthorBio")
);
const AuthorBioDetail = lazy(
  () => import("pages/AuthorBioManagement/AuthorBioDetail")
);
const Test = lazy(() => import("pages/Test/index"));
const renderLoader = () => <LoadSpinner isOverlay={true} isLoading={true} />;

const AppWrapper = styled.div`
  height: 100%;
`;

function App() {
  const themeMode = useSelector((state: RootState) => state.themeToggle.mode);
  const { isAuthenticated } = useUser();

  return (
    <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
      <AppWrapper>
        <Toastify />
        {isAuthenticated === false ? (
          <Switch>
            <Suspense fallback={renderLoader()}>
              <Redirect exact from="/" to={Routes.LOGIN} />
              <Route path={Routes.LOGIN} component={LoginPage} />
            </Suspense>
          </Switch>
        ) : (
          <DefaultLayout
            boardContent={
              <>
                <Suspense fallback={renderLoader()}>
                  <Switch>
                    <Route exact path={"/test"} component={Test} />
                    <Route
                      exact
                      path={Routes.LIST_AUCTION_DATA}
                      component={AuctionDataManagement}
                    />
                    <Route
                      exact
                      path={Routes.CREATE_AUCTION_DATA}
                      component={CreateAuctionData}
                    />
                    <Route
                      exact
                      path={Routes.DETAIL_AUCTION_DATA}
                      component={AuctionDataDetail}
                    />
                    <Route
                      exact
                      path={Routes.LIST_AUTHOR_BIO}
                      component={AuthorBioManagement}
                    />
                    <Route
                      exact
                      path={Routes.CREAT_AUTHOR_BIO}
                      component={CreateAuthorBio}
                    />
                    <Route
                      exact
                      path={Routes.DETAIL_AUTHOR_BIO}
                      component={AuthorBioDetail}
                    />
                    <Redirect to={Routes.LIST_AUCTION_DATA} from="*" />
                  </Switch>
                </Suspense>
              </>
            }
          />
        )}
        <GlobalStyles />
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
