import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .logo {
    margin-left: 30px;
  }

  .trigger {
    font-size: 24px;
    padding: 7px 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.mainColor};
    color: ${(props) => props.theme.secondaryTextColor};
  }
`;
