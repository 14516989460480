import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .input--button__money_label {
    font-family: "NotoSansKR-Regular", sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;

    &:hover {
      border-color: #4096ff;
      border-inline-end-width: 1px;
    }

    &:focus {
      border-color: #4096ff;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      border-inline-end-width: 1px;
      outline: 0;
    }

    &:disabled {
      border-color: #d9d9d9;
      border-inline-end-width: 1px;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
  }

  .ant-input {
    font-size: 16px;
  }
`;

export const UnitText = styled.div`
  position: absolute;
  top: 13px;
  right: 16px;
  font-weight: bold;
  font-size: 16px;
`;
