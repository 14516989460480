const routes = {
  LOGIN: "/login",
  DASHBOARD: "/",
  AUCTION_DATA: "/auction-data-management",
  LIST_AUCTION_DATA: "/auction-data-management/list",
  CREATE_AUCTION_DATA: "/auction-data-management/create",
  PRE_DETAIL_AUCTION_DATA: "/auction-data-management/detail",
  DETAIL_AUCTION_DATA: "/auction-data-management/detail/:id",

  AUTHOR_BIO: "/author-bio-management",
  LIST_AUTHOR_BIO: "/author-bio-management/list",
  CREAT_AUTHOR_BIO: "/author-bio-management/create",
  DETAIL_AUTHOR_BIO: "/author-bio-management/detail/:id",
  PRE_DETAIL_AUTHOR_BIO: "/author-bio-management/detail",
};

export default routes;
