import { Input } from "antd";
import React from "react";
import { NumericFormat } from "react-number-format";
import * as S from "./style";

interface Props extends React.InputHTMLAttributes<any> {
  btntype?: "MONEY_LABEL" | "LINE" | "NUMBER" | "TEXT";
  type?: string;
  text?: string;
  className?: string;
  onChange?: any;
  value?: any;
  placeholder?: string;
  unittext?: string;
  ref?: any;
  disabled?: boolean;
  searchText?: string;
  onClick?: any;
  accept?: string;
  name?: string;
  defaultValue?: string;
  inputLeft?: string;
  labelLeft?: string;
  labelMt?: string;
  onPressEnter?: () => any;
}

const InputMoneyLabel = ({
  className,
  onChange,
  value,
  placeholder,
  unittext,
  disabled,
  name,
  defaultValue,
}: Props) => {
  return (
    <S.Container className={className}>
      <NumericFormat
        displayType="input"
        value={value}
        thousandSeparator=","
        placeholder={placeholder}
        className="field-input input--button__money_label"
        onChange={onChange}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
      />
    </S.Container>
  );
};

const InputNumber = ({
  className,
  onChange,
  value,
  placeholder,
  unittext,
  disabled,
  defaultValue,
  name,
}: Props) => {
  return (
    <S.Container className={className}>
      <NumericFormat
        displayType="input"
        value={value}
        placeholder={placeholder}
        className="field-input input--button__money_label"
        onChange={onChange}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
      />
      <S.UnitText>{unittext}</S.UnitText>
    </S.Container>
  );
};

const InputLine = ({
  type,
  className,
  onChange,
  value,
  placeholder,
  unittext,
  ref,
  disabled,
  name,
  defaultValue,
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        ref={ref}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
      />
      <S.UnitText>{unittext}</S.UnitText>
    </S.Container>
  );
};

const InputBase = ({
  type,
  className,
  onChange,
  value,
  placeholder,
  ref,
  disabled,
  name,
  defaultValue,
  style,
  onPressEnter
}: Props) => {
  return (
    <S.Container className={className}>
      <Input
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        ref={ref}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
        style={style}
        onPressEnter={onPressEnter}
      />
    </S.Container>
  );
};

const InputMain = (props: Props, ref: any) => {
  const {
    btntype,
    type,
    className,
    onChange,
    value,
    unittext,
    placeholder,
    disabled,
    name,
    defaultValue,
    onPressEnter
  } = props;

  const renderInput = () => {
    switch (btntype) {
      case "MONEY_LABEL":
        return (
          <InputMoneyLabel
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type={type}
            unittext={unittext}
            className={className}
            disabled={disabled}
            name={name}
            defaultValue={defaultValue}
          />
        );
      case "NUMBER":
        return (
          <InputNumber
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type={type}
            unittext={unittext}
            className={className}
            disabled={disabled}
            name={name}
            defaultValue={defaultValue}
          />
        );
      case "LINE":
        return (
          <InputLine
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type={type}
            unittext={unittext}
            className={className}
            disabled={disabled}
            name={name}
            defaultValue={defaultValue}
          />
        );
      case "TEXT":
        return (
          <InputBase
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type={type}
            unittext={unittext}
            className={className}
            disabled={disabled}
            name={name}
            defaultValue={defaultValue}
            onPressEnter={onPressEnter}
          />
        );
      default:
        return (
          <InputBase
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            type={type}
            unittext={unittext}
            className={className}
            disabled={disabled}
            name={name}
            defaultValue={defaultValue}
            onPressEnter={onPressEnter}
          />
        );
    }
  };

  return renderInput() ?? null;
};

export default InputMain;
