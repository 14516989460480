import styled from "styled-components";
import { Button, Input, Row, Space, Checkbox } from "antd";
const { Search } = Input;

export const BtnFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
  height: 50px;
  padding: 8px 32px;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    max-width: 120px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    padding: 0 8px;
  }

  :hover,
  :focus {
    span {
      opacity: 0.6;
    }
  }
  span {
    font-family: NotoSansKR-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
  }

  &.active-btn span {
    color: #5934cb;
  }
`;
export const SearchStyle = styled(Search)`
  margin-left: 20px;

  &.ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 50px;
    max-width: 190px;
    padding-left: 20px;

    @media screen and (max-width: 1024px) {
      max-width: 140px;
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      min-width: 120px;
      height: 40px;
    }
  }
  .ant-input:placeholder-shown {
    text-overflow: unset;
  }

  &.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    min-width: 60px;
    height: 50px;
    border: none;
    border-radius: 0 10px 10px 0;
    color: #272727;
    background-color: #f5f5f5;
    font-family: NotoSansKR-Medium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;

    @media screen and (max-width: 1024px) {
      max-width: 50px;
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      min-width: 50px;
      height: 40px;
    }
  }
  &.ant-input-group-wrapper {
    width: auto;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: solid 1px #e8e8e8;
  }
  :hover,
  :focus {
    border: none;
    opacity: 0.8;
  }

  button {
    background-color: #584475 !important;
    color: #fff !important;
  }
`;
export const ButtonStyle = styled(Button)`
  min-width: 80px;
  height: 40px;
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  background-color: #f5f5f5;

  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;

  :hover,
  :focus {
    color: #202020;
    background-color: #f5f5f5;
    border: none;
    opacity: 0.8;
  }

  @media screen and (max-width: 1024px) {
    width: 60px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 60px;
    height: 40px;
  }
`;
export const ContainerFilters = styled(Row)`
  width: 100%;
  height: fit-content;
  background-color: #f5f5f5;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-top: 14px;
  padding: 8px;
`;
export const SpaceStyle = styled(Space)`
  margin-top: 4px;
  justify-content: end;
  .ant-checkbox-group-item {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #3f444d;
    margin-left: 0;
  }

  .ant-input {
    border: none;
    padding: 8px 16px 8px 0;
    border-bottom: solid 1px #e8e8e8;
    outline: none;
    font-family: NotoSansKR-Regular;
    font-size: 14px;

    &::placeholder {
      font-family: NotoSansKR-Regular;
      color: #949494;
    }

    &:focus {
      box-shadow: none;
    }
  }
`;
export const LiStyle = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #3f444d;
  margin: 0 20px;
  font-weight: 600;
  text-wrap: nowrap;
  cursor: pointer;
  :hover,
  :focus {
    opacity: 0.8;
  }
  &.active-li {
    font-family: NotoSansKR-Bold;
    font-weight: 700;
    color: #5934cb;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #5934cb;
  }
  &.popover-disabled {
    .filter-level-2 {
      color: #bbb;
    }
    cursor: not-allowed;
  }
`;

export const Summary = styled.div`
  margin-left: 20px;
  flex-grow: 1;
`;

export const ContainerInput = styled.div`
  position: relative;

  .icon-search {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 16px;
    transform: translateY(-50%);
    color: #3f444d;
    cursor: pointer;
  }
`;

export const WrapSearch = styled.div`
  display: flex;
  justify-content: flex-end;

  .btn-download {
    height: 50px;
    margin-right: 16px;
    background-color: #584475;
    color: #fff;
    border-color: #584475;

    &:hover {
      border-color: #584475;
      color: #fff;
    }

    &:disabled {
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      color: #000;
    }
  }
`;

export const FilterWrap = styled.div`
  flex: 1;

  .filterWrap {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
  }
`

export const WrapContent = styled.div`
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    line-height: 33px !important;
  }
`;

export const CheckBox = styled.div`
  /* max-height: 500px;
  overflow-y: 'auto' */
`;

export const CheckBoxGroup = styled(Checkbox.Group)`
  max-height: 500px;
  overflow: auto;
  .ant-checkbox-group-item {
    width: 100%;
    margin: 3px 0;
  }
`;
