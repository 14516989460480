import { Modal } from "antd";
import styled from "styled-components";

export const Popup = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      font-family: "NotoSansKR-Regular";
      font-size: 16px;
      text-align: center;
      padding: 24px 0;
    }

    .ant-modal-footer {
      text-align: center;
    }
  }

  .btn-ok {
    background-color: #886ab5;
    color: #fff;
    border-color: #886ab5;

    &:hover {
      border-color: #886ab5;
      color: #fff;
    }
  }
`;
