export const replaceNumber = (value) => {
  return parseInt(value.replace(/[^0-9]/g, ""));
};

export const stdToNumber = (value) => {
  return parseInt(value, 0);
};

export const numberToStd = (value) => {
  return value.toString();
};

export const calculateCompetitiveRate = (startPrice: any, hammerPrice: any) => {
  if (!hammerPrice) return "";
  if (!startPrice) return 0;
  startPrice = startPrice.replaceAll(",", "");
  hammerPrice = hammerPrice.replaceAll(",", "");
  if (Number(startPrice) === 0) {
    return hammerPrice / 50000;
  } else {
    return hammerPrice / startPrice - 1;
  }
};

export const convertValueObject = (values: any, author = false) => {
  let newValues = values;
  for (let i in newValues) {
    // if (i === "artistBirth" || i === "artistDeath") continue;
    if (values[i] === undefined) {
      delete values[i];
    }
    if (values[i] === null && author) {
      delete values[i];
    }
  }
  return newValues;
};

export const convertNumber = (value: any, except?: boolean) => {
  if (typeof value === "number") return value;
  if (except) {
    if (value === "") return 0;
    if (Number(value) === 0) return null;
  }
  return value ? Number(value.replaceAll(",", "")) : null;
};

export const MoneyConvert = (value: any, toFixed?: boolean) => {
  if (value === undefined || value === null || value === "") return 0;
  if (toFixed) {
    return Number(value)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  if (Number.isInteger(Number(value)))
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const checkSort = (sort) => {
  if (!sort) return null;
  if (sort === "asc") {
    return "ascend";
  } else if (sort === "desc") {
    return "descend";
  }
};
