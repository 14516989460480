import styled from "styled-components";

export const Container = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: #e5e8e7;
    &:hover {
      color: #000 !important;
      border-color: #d9d9d9 !important;
    }
  }
`;

export const Title = styled.div`
  font-family: "NotoSansKR-Regular";
`;

export const Btn = styled.div`
  .ant-btn > span {
    font-family: "NotoSansKR-Regular";
  }
`;
