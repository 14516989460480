import styled, { css } from "styled-components";

export const Container = styled.div<{ collapse?: boolean; width?: number }>`
  .ant-descriptions-view {
    ${({ collapse }) =>
      collapse
        ? css`
            border: none !important;
          `
        : css`
            border: 1px solid #d9d9d9 !important;
          `}

    border-radius: 0;
  }

  .ant-descriptions-item-label {
    font-family: "NotoSansKR-Regular";
    font-size: 16px;
    color: #000 !important;

    ${({ width }) =>
      width
        ? css`
            width: ${width}%;
          `
        : ""}

    ${({ collapse }) =>
      collapse
        ? css`
            width: 25%;
          `
        : ""}
  }

  .ant-descriptions-item-content {
    font-family: "NotoSansKR-Regular";
    font-size: 16px;
    color: #000 !important;

    ${({ width }) =>
      width
        ? css`
            width: ${width}%;
          `
        : ""}
  }

  .ant-descriptions-title {
    font-family: "NotoSansKR-Regular";
    font-size: 18px;
    font-weight: bold;
  }

  .ant-descriptions-row {
    border-bottom-color: #d9d9d9 !important;

    .ant-descriptions-item-label {
      border-inline-end-color: #d9d9d9;
    }
  }

  .ant-descriptions .ant-descriptions-header {
    margin-bottom: 0;
    border: 1px solid #d9d9d9;
    padding: 16px 24px;
    border-bottom: none;
    background-color: #ebf1ff;
  }
`;
