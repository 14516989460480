export function isEmpty(arg) {
  return (
    arg == null ||
    arg.length === 0 ||
    (typeof arg === "object" && Object.keys(arg).length === 0)
  );
}

export function checkValidForm(valuesForm = {}) {
  return Object.keys(valuesForm).some((key) => {
    if (valuesForm[key]?.length > 0) return true;
    return false;
  });
}
