import { DatePicker, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DMYFormat, YearFormat } from "utils/format/formatDate";

const RangeDatePicker = ({ name }) => {
  const { setValue, getValues } = useFormContext();
  const fieldValue = getValues(name) || [];
  const [minDate, setMinDate] = useState<any>(() =>
    fieldValue[0]?.length > 0 ? moment(fieldValue[0]) : null
  );
  const [maxDate, setMaxDate] = useState<any>(() =>
    fieldValue[1]?.length > 0 ? moment(fieldValue[1]) : null
  );

  // const disabledDate = (currentDate) => {
  //   // return currentDate && currentDate < dayjs(minDate).endOf("day");
  //   return currentDate && currentDate.valueOf() < Date.parse(minDate);
  // };

  useEffect(() => {
    if (!minDate && !maxDate) {
      return setValue(`${name}`, undefined);
    }
    if (name === "transactDate") {
      const minDatePicker = minDate?.format("YYYY-MM-DD");
      const maxDatePicker = maxDate?.format("YYYY-MM-DD");
      setValue(`${name}`, [minDatePicker ?? "", maxDatePicker ?? ""], {
        shouldDirty: true,
      });
    }
    if (name === "mfgDate") {
      const minDatePicker = minDate?.format("YYYY");
      const maxDatePicker = maxDate?.format("YYYY");
      setValue(`${name}`, [minDatePicker ?? "", maxDatePicker ?? ""], {
        shouldDirty: true,
      });
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    if (!getValues(name)) {
      setMinDate(null);
      setMaxDate(null);
    }
  }, [JSON.stringify(getValues(name))]);
  return (
    <>
      {name === "transactDate" ? (
        <Space>
          <DatePicker
            value={minDate}
            format={DMYFormat}
            size="small"
            placeholder="Start date"
            onChange={(date, dateString) => setMinDate(date)}
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current > moment(customDate, "YYYY-MM-DD");
            }}
          />
          <span>~</span>
          <DatePicker
            value={maxDate}
            format={DMYFormat}
            size="small"
            placeholder="End date"
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current > moment(customDate, "YYYY-MM-DD");
            }}
            onChange={(date, dateString) => setMaxDate(date)}
          />
        </Space>
      ) : (
        <Space>
          <DatePicker
            value={minDate}
            format={YearFormat}
            name="minYear"
            picker="year"
            size="small"
            placeholder="Start year"
            onChange={(date, dateString) => setMinDate(date)}
            disabledDate={(current) => {
              let customDate = moment().format("YYYY");
              return current && current > moment(customDate, "YYYY");
            }}
          />
          <span>~</span>
          <DatePicker
            value={maxDate}
            format={YearFormat}
            picker="year"
            placeholder="End year"
            size="small"
            disabledDate={(current) => {
              let customDate = moment().format("YYYY");
              return current && current > moment(customDate, "YYYY");
            }}
            onChange={(date, dateString) => setMaxDate(date)}
          />
        </Space>
      )}
    </>
  );
};

export default React.memo(RangeDatePicker);
