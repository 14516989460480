import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { NAME_FILTERS } from "constants/filtersName";
import * as S from "./style";
import Chip from "./Chip/Chip";

const FieldsValueFilters = ({ handleDeleteFilter }) => {
  const { reset } = useFormContext();
  const watchFields = useWatch();

  const { querySearch, ...resFields } = watchFields;
  const isValidFields = Object.keys(resFields).some((key) => {
    if (resFields[key]?.length <= 0 || !resFields[key]) return false;
    return true;
  });


  return (
    <>
      {isValidFields && (
        <S.SpaceStyle wrap align="center">
          {Object.keys(NAME_FILTERS).map((key) => (
            <Chip
              key={key}
              label={key}
              content={watchFields[key]}
              handleDeleteFilter={handleDeleteFilter}
            />
          ))}
          <S.ButtonStyle
            onClick={() => {
              reset((formValues) => {
                return Object.keys(formValues).reduce((objReset, field) => {
                  objReset[field] = "";
                  return objReset;
                }, {});
              });
              handleDeleteFilter(true);
            }}
          >
            초기화
          </S.ButtonStyle>
        </S.SpaceStyle>
      )}
    </>
  );
};

export default FieldsValueFilters;
