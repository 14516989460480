import { createSlice } from "@reduxjs/toolkit";

export interface CounterState {
  mode: string;
}

const initialState: CounterState = {
  mode: "light",
};

export const themeSlice = createSlice({
  name: "themeSwicth",
  initialState,
  reducers: {
    darkTheme: (state) => {
      state.mode = "dark";
    },
    lightTheme: (state) => {
      state.mode = "light";
    },
  },
});

// Action creators are generated for each case reducer function
export const { darkTheme, lightTheme } = themeSlice.actions;

export default themeSlice.reducer;
