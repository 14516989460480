import { memo, useLayoutEffect, useRef, useState } from "react";
import { Layout } from "antd";

import { Header as AppHeader, LoadSpinner, SideBar } from "components";
import { useLocation } from "react-router-dom";
import * as S from "./style";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducer";

interface Props {
  boardContent?: any;
}
const { Header, Content, Sider } = Layout;

function DefaultLayout(props: Props) {
  const { boardContent } = props;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const location = useLocation();
  const refContent = useRef(null);
  const { isLoading } = useSelector((state: RootState) => state.loading);

  useLayoutEffect(() => {
    refContent.current.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <S.Wrapper>
      <LoadSpinner isLoading={isLoading} isOverlay={isLoading} />
      <Layout>
        <Header className="header">
          <AppHeader handleDrawerOpen={() => setCollapsed((prev) => !prev)} />
        </Header>
        <Layout>
          <Sider
            className="sider"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <SideBar collapsed={collapsed} />
          </Sider>
          <Content className="content" ref={refContent} id="content">
            <S.MainContent>{boardContent}</S.MainContent>
          </Content>
        </Layout>
      </Layout>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
