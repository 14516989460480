import { AutoComplete, Button } from "antd";
import React from "react";

import * as S from "./style";

interface Props extends React.SelectHTMLAttributes<any> {
  className?: string;
  options?: any;
  text?: string;
  defaultValueAutoComplete?: any;
  placeholder?: string;
  allowClear?: boolean;
  onSelect?: any;
  handleOnClick?: any;
  onChangeComplete?: any;
}

const Autocomplete = (
  {
    options,
    placeholder,
    allowClear,
    onSelect,
    handleOnClick,
    onChangeComplete,
    defaultValueAutoComplete,
  }: Props,
  ref
): React.ReactElement => {
  return (
    <S.Container>
      <AutoComplete
        options={options}
        filterOption={(inputValue: any, option: any) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={onChangeComplete}
        placeholder={placeholder}
        allowClear={allowClear}
        onSelect={onSelect}
        ref={ref}
        defaultValue={defaultValueAutoComplete}
        // value={defaultValueAutoComplete}
      />
      <Button onClick={handleOnClick}>검색</Button>
    </S.Container>
  );
};

export default React.forwardRef(Autocomplete);
