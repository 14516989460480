import { PlusOutlined } from "@ant-design/icons";
import { Image, Input } from "antd";
import NoImage from "assets/images/noimg.jpeg";
import * as S from "./style";

interface Props {
  getDataFile?: any;
  singleData?: any;
  deleteFile?: any;
}

function FileItem({ getDataFile, singleData, deleteFile }: Props) {
  const srcImage =
    singleData?.src ?? singleData?.image_url ?? singleData ?? null;

  const handleChangeFile = (file: any, index: any) => {
    const urlPreview = URL.createObjectURL(file[0]);
    getDataFile?.({
      name: file[0].name,
      id: index,
      form: file[0],
      src: urlPreview,
    });
  };

  return (
    <S.Container>
      {srcImage ? (
        <>
          <Image 
            src={srcImage} 
            onError={(event:any) => {
              event.target.src = NoImage;
              event.onerror = null;
            }} 
            className="image" 
          />
          <S.DeleteFile onClick={() => deleteFile?.(singleData?.id)}>
            del
          </S.DeleteFile>
        </>
      ) : (
        <>
          <Input
            type="file"
            className="input-file"
            onChange={(e: any) => handleChangeFile(e.target.files, Date.now())}
          />
          <PlusOutlined className="icon-plus" />
        </>
      )}
    </S.Container>
  );
}

export default FileItem;
