import styled, { css } from "styled-components";

export const Container = styled.div`
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-color: #584475;

    &::after {
      border-color: #584475;
      border-width: 3px;
      height: 11px;
    }
  }

  .ant-checkbox-wrapper .ant-checkbox-checked:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
    .ant-checkbox-checked:hover
    .ant-checkbox-inner {
    background-color: #fff;
    border-radius: 0;
    border-color: #584475;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #fff;
    border-radius: 0;
    border-color: #584475;
  }

  .ant-checkbox-checked:after {
    border: none;
  }

  .ant-checkbox + span {
    font-size: 16px;
    font-family: "NotoSansKR-Regular";
  }
`;

export const AnyText = styled.div<{ checked?: boolean }>`
  font-size: 16px;
  font-family: "NotoSansKR-Regular";

  ${({ checked }) =>
    checked
      ? css`
          color: #ccc;
          font-weight: normal;
        `
      : css`
          font-weight: bold;
        `}
`;
