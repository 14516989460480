import { Button, Input, Row, Select } from "antd";
import {
  AUC_COM,
  BID_CLASS,
  CERTIFICATION_LIST,
  LOCATION,
  MATERIAL,
  NAME_FILTERS,
  ON_OFF_LIST,
  dataMethods,
} from "constants/filtersName";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getParamUrlValue } from "utils/url";
import CheckboxActive from "./CheckboxActive";
import CheckboxRender from "./CheckboxRender";
import FieldsValueFilters from "./FieldsValueFilters";
import PopoverEl from "./PopoverEl";
import RangeDatePicker from "./RangeDatePicker";
import SelectMinMax from "./SelectMinMax";
import { dataLocation, dataPreferences } from "constants/dataSelect";
import * as S from "./style";
import debounce from "lodash.debounce";
import { getDataSelectLevel2 } from "services/Auction";
import { formatDataFilter } from "utils/format/formatDataFilter";

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

interface Props {
  filter?: any;
  search?: boolean;
  searchName?: boolean;
  className?: any;
  children?: any;
  onValueChangeFilter?: any;
  handleDownloadExcel?: any;
  handleDownloadFilter?: any;
  rowSelected?: any;
}

const FilterForm = ({
  filter,
  search = false,
  searchName = false,
  className,
  children,
  onValueChangeFilter,
  handleDownloadExcel,
  handleDownloadFilter,
  rowSelected,
}: Props) => {
  const location = useLocation();
  //initial value form
  const defaultValues = {
    querySearch: getParamUrlValue(location, "querySearch"),
    bidClass: getDefaultValue(getParamUrlValue(location, "bidClass")),
    pricetp: getParamUrlValue(location, "pricetp"),
    price: getParamUrlValue(location, "price"),
    material: getDefaultValue(getParamUrlValue(location, "material")),
    materialSearch: getParamUrlValue(location, "materialSearch"),
    transactDate: getDefaultValue(getParamUrlValue(location, "transactDate")),
    mfgDate: getDefaultValue(getParamUrlValue(location, "mfgDate")),
    height: getDefaultValue(getParamUrlValue(location, "height")),
    width: getDefaultValue(getParamUrlValue(location, "width")),
    title: getParamUrlValue(location, "title"),
    company: getDefaultValue(getParamUrlValue(location, "company")),
    location: getDefaultValue(getParamUrlValue(location, "location")),
    onOff: getParamUrlValue(location, "onOff"),
    certification: getParamUrlValue(location, "certification"),
    auctionName: getParamUrlValue(location, "auctionName"),
    sizeTable: getDefaultValue(getParamUrlValue(location, "sizeTable")),
    method: getDefaultValue(getParamUrlValue(location, "method")),
    mainColor: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "mainColor")) : undefined,
    series: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "series")) : undefined,
    preference: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "preference")) : undefined
  };
  const [reloadFilter, setReloadFilter] = useState(false);
  const [dataSelectLevel2, setDataSelectLevel2] = useState(null);

  const useFormMethods = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
    setValue
  } = useFormMethods;

  /**
   *  event search of form search
   * @param {object} values value of form search
   * @returns {void}
   */
  const onSearch = (values) => {
    if (!checkExistFilterLevel1(values)) {
      clearLevel2Filter(values);
    }
    onValueChangeFilter?.(values);
  };

  // get only fields changed in filter level1 
  const changeFilterLevel1 = (values) => {
    const newValues = { ...values }
    delete newValues.mainColor
    delete newValues.series
    delete newValues.preference
    return newValues
  }


  //check fields of filter level 1
  const checkExistFilterLevel1 = (values) => {
    const filterLevel1 = changeFilterLevel1(values);
    for (const properties in filterLevel1) {
      if (filterLevel1[properties] && filterLevel1[properties]?.length > 0) return true;
    }
    return false;
  }

  //set values of tags when filter level 2 changed
  const handleChangeFilterLevel2 = (key, datas) => {
    if (watch(key)) {
      const newArray = watch(key)?.filter((item) => datas?.includes(item));
      setValue(key, newArray);
    }
  }

  // clear tag and filter level 2 when filter level 1 undefined
  const clearLevel2Filter = (values) => {
    values.mainColor = undefined;
    setValue("mainColor", undefined);
    values.series = undefined;
    setValue("series", undefined);
    values.preference = undefined;
    setValue("preference", undefined);
  }

  const getSelectLevel2 = async (values) => {
    try {
      const checkFilterLevel1 = checkExistFilterLevel1(values);
      if (checkFilterLevel1) {
        const resp = await getDataSelectLevel2(values);
        console.log(dataPreferences.filter(item => resp.data.preference.includes(item)) );
        if (resp) {
          setDataSelectLevel2({
            mainColor: resp.data.main_color,
            series: resp.data.series,
            preference: dataPreferences.filter(item => resp.data.preference.includes(item)) 
          })
          handleChangeFilterLevel2("mainColor", resp?.data?.main_color);
          handleChangeFilterLevel2("series", resp?.data?.series);
          handleChangeFilterLevel2("preference", resp?.data?.preference);
        }
      } else {
        setDataSelectLevel2(null);
        clearLevel2Filter(values);
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  useEffect(() => {
    if (reloadFilter) {
      onSearch(watch());
    }
    onSearch(watch());
  }, [JSON.stringify(watch())]);

  useEffect(() => {
    getSelectLevel2(formatDataFilter(watch()));
  }, [JSON.stringify(changeFilterLevel1(filter))])

  const dataLocationUsed = dataLocation.map((x) => {
    return x.label;
  });

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onSearch)}>
        {/* <S.WrapSearch>
          {searchName && (
            <Controller
              name="querySearch"
              control={control}
              render={({ field }) => (
                <S.SearchStyle
                  {...field}
                  placeholder="검색어를 입력해주세요."
                  allowClear
                  enterButton={<Button htmlType="submit">검색</Button>}
                />
              )}
            />
          )}
          <Button
            onClick={handleDownloadExcel}
            className="btn-download"
            disabled={!rowSelected || rowSelected.length <= 0}
          >
            엑셀다운
          </Button>
        </S.WrapSearch> */}
        <>
          <S.ContainerFilters className={className}>
            <S.WrapSearch>
              {searchName && (
                <Controller
                  name="querySearch"
                  control={control}
                  render={({ field }) => (
                    <S.SearchStyle
                      {...field}
                      placeholder="검색어를 입력해주세요."
                      allowClear
                      enterButton={<Button htmlType="submit">검색</Button>}
                    />
                  )}
                />
              )}
            </S.WrapSearch>
            <S.FilterWrap>
              <div className="filterWrap">
                <PopoverEl
                  name="company"
                  text={NAME_FILTERS.company}
                  content={<CheckboxRender name="company" options={AUC_COM} />}
                />
                <PopoverEl
                  name="auctionName"
                  text={NAME_FILTERS.auctionName}
                  content={
                    <Controller
                      name="auctionName"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} size="small" placeholder="경매명" />
                      )}
                    />
                  }
                />
                <PopoverEl
                  name="transactDate"
                  text={NAME_FILTERS.transactDate}
                  content={<RangeDatePicker name="transactDate" />}
                />
                {/* <PopoverEl
                  name="certification"
                  text={NAME_FILTERS.certification}
                  content={
                    <CheckboxActive
                      name="certification"
                      options={CERTIFICATION_LIST}
                    />
                  }
                /> */}
                <PopoverEl
                  name="location"
                  text={NAME_FILTERS.location}
                  content={
                    <CheckboxRender name="location" options={dataLocationUsed} />
                  }
                />
                <PopoverEl
                  name="onOff"
                  text={NAME_FILTERS.onOff}
                  content={<CheckboxActive name="onOff" options={ON_OFF_LIST} />}
                />
                <PopoverEl
                  name="title"
                  text={NAME_FILTERS.title}
                  content={
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} size="small" placeholder="작품명 입력" />
                      )}
                    />
                  }
                />
                <PopoverEl
                  name="mfgDate"
                  text={NAME_FILTERS.mfgDate}
                  content={<RangeDatePicker name="mfgDate" />}
                />
                <PopoverEl
                  name={["material", "materialSearch"]}
                  text={NAME_FILTERS.material}
                  content={
                    <CheckboxRender
                      inputSearch
                      name="material"
                      options={MATERIAL}
                    />
                  }
                />
                <PopoverEl
                  name="height"
                  text={NAME_FILTERS.height}
                  content={<SelectMinMax name="height" />}
                />
                <PopoverEl
                  name="width"
                  text={NAME_FILTERS.width}
                  content={<SelectMinMax name="width" />}
                />
                <PopoverEl
                  name="sizeTable"
                  text={NAME_FILTERS.sizeTable}
                  content={<SelectMinMax name="sizeTable" />}
                />
                <PopoverEl
                  name={["pricetp", "price"]}
                  text={NAME_FILTERS.price}
                  content={<SelectMinMax pricept name="price" />}
                />
                {search && (
                  <>
                    {/* <PopoverEl
                      name="sellOrNot"
                      text={NAME_FILTERS.sellOrNot}
                      content={
                        <CheckboxRender name="sellOrNot" options={SUCCESS} />
                      }
                    /> */}
                    <PopoverEl
                      name="bidClass"
                      text={NAME_FILTERS.bidClass}
                      content={
                        <CheckboxRender name="bidClass" options={BID_CLASS} />
                      }
                    />
                  </>
                )}
                <PopoverEl
                  name="method"
                  text={NAME_FILTERS.method}
                  content={
                    <CheckboxRender name="method" options={dataMethods} />
                  }
                />
                {dataSelectLevel2 && <PopoverEl
                  name="mainColor"
                  text={<p className="filter-level-2">{NAME_FILTERS.mainColor}</p>}
                  content={
                    <CheckboxRender name="mainColor" options={dataSelectLevel2?.mainColor} />
                  }
                  disabled={dataSelectLevel2?.mainColor.length === 0}
                />
                }
                {dataSelectLevel2 && <PopoverEl
                  name="series"
                  text={<p className="filter-level-2">{NAME_FILTERS.series}</p>}
                  content={
                    <CheckboxRender name="series" options={dataSelectLevel2?.series} />
                  }
                  disabled={dataSelectLevel2?.series.length === 0}
                />
                }
                {dataSelectLevel2 && <PopoverEl
                  name="preference"
                  text={<p className="filter-level-2">{NAME_FILTERS.preference}</p>}
                  content={
                    <CheckboxRender name="preference" options={dataSelectLevel2?.preference} />
                  }
                  disabled={dataSelectLevel2?.preference.length === 0}
                />}
              </div>
            </S.FilterWrap>

            {/* <PopoverEl
              name="location"
              text={NAME_FILTERS.location}
              content={<CheckboxRender name="location" options={LOCATION} />}
            /> */}
            {/* <S.WrapSearch>
              {searchName && (
                <Controller
                  name="querySearch"
                  control={control}
                  render={({ field }) => (
                    <S.SearchStyle
                      {...field}
                      placeholder="검색어를 입력해주세요."
                      allowClear
                      enterButton={<Button htmlType="submit">검색</Button>}
                    />
                  )}
                />
              )}
              <Button
                onClick={handleDownloadExcel}
                className="btn-download"
                disabled={!rowSelected || rowSelected.length <= 0}
              >
                엑셀셀다운(선택)
              </Button>
              <Button
                onClick={handleDownloadFilter}
                className="btn-download"
              >
                엑셀다운(모두)
              </Button>
            </S.WrapSearch> */}
          </S.ContainerFilters>
          <Row
            wrap={false}
            justify={"space-between"}
            align={"top"}
            style={{ padding: "16px 32px", gap: 48 }}
          >
            <S.WrapSearch>
              <Button
                onClick={handleDownloadExcel}
                className="btn-download"
                disabled={!rowSelected || rowSelected.length <= 0}
              >
                엑셀셀다운(선택)
              </Button>
              <Button
                onClick={handleDownloadFilter}
                className="btn-download"
              >
                엑셀다운(모두)
              </Button>
            </S.WrapSearch>
            <FieldsValueFilters handleDeleteFilter={setReloadFilter} />
          </Row>
          {/* <FieldsValueFilters handleDeleteFilter={setReloadFilter} /> */}
        </>
      </form>
    </FormProvider>
  );
};

export default FilterForm;
