import styled from "styled-components";

export const Container = styled.div<{ collapse?: boolean; width?: number }>`
  width: 150px;
  height: 150px;
  border: 1px solid #000;
  position: relative;

  .input-file {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .icon-plus {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .ant-image {
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const DeleteFile = styled.div`
  position: absolute;
  border: none;
  font-size: 12px;
  background-color: #886ab5;
  border-color: #886ab5;
  bottom: -10px;
  right: -1px;
  padding: 1px 16px;
  color: #fff;
  cursor: pointer;
`;
