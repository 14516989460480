import { CloseSquareOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import * as S from "./style";
import { Input } from "components";

interface Props {
  list?: any;
  type?: "VER" | "HOR";
  getValue?: any;
  deleteValue?: any;
  onClickDetail?: any;
  placeholder?: string;
}

function IdenticalRecords({
  list,
  type,
  getValue,
  deleteValue,
  onClickDetail,
  placeholder,
}: Props) {
  const [idValue, setIdValue] = useState("");

  const handleOnChange = (val: any) => {
    setIdValue(val);
  };

  const handleOnClick = () => {
    if (idValue === "") return;
    getValue?.(idValue);
    setIdValue("");
  };

  const handleOnClickDetail = (val: any) => {
    if (type !== "HOR") {
      return;
    }
    onClickDetail?.(val);
  };

  return (
    <S.Container className={type === "HOR" ? "d-flex" : ""}>
      <S.WrapInput>
        <Input
          placeholder={placeholder}
          className="input-add"
          onChange={(e: any) => handleOnChange(e.target.value)}
          value={idValue}
          btntype={type === "HOR" ? "NUMBER" : "TEXT"}
        />
        {/* <Button onClick={handleOnClick}>
          <PlusOutlined />
        </Button> */}
      </S.WrapInput>
      <S.WrapId className={type !== "HOR" ? "mt-8" : ""}>
        {list &&
          list.map((item: any, index: any) => (
            <S.ItemRecord key={index}>
              <S.Value
                className={type === "HOR" ? "text-black" : ""}
                onClick={() => handleOnClickDetail(item)}
              >
                {item.title ?? item}
              </S.Value>
              <CloseSquareOutlined
                className="icon-delete"
                onClick={() => deleteValue?.(item)}
              />
            </S.ItemRecord>
          ))}
      </S.WrapId>
    </S.Container>
  );
}

export default IdenticalRecords;
