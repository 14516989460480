import styled from "styled-components";

export const SidebarHead = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;
  font-family: "NotoSansKR-Regular";

  p {
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-bottom: 8px;
    font-family: "NotoSansKR-Regular";
  }
`;

export const Username = styled.div`
  font-size: 26px;
  text-align: center;
  padding: 0 8px;
  color: #fff;
  font-weight: bold;
`;

export const IconUserName = styled.div`
  text-align: center;
  color: #fff;

  .icon-round {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 50%;
  }
`;

export const Sidebar = styled.div`
  height: 100%;
  position: relative;
`;

export const SidebarBody = styled.div`
  padding: 16px 0 32px;
  font-family: "NotoSansKR-Regular";

  .ant-menu-light {
    background: inherit;
    color: #fff;
    font-family: "NotoSansKR-Regular";
    font-size: 16px;
  }

  .ant-menu-light .ant-menu-item {
    &:hover {
      color: #fff !important;
    }
    a {
      font-family: "NotoSansKR-Regular";
    }
  }

  .ant-menu-item {
    &.ant-menu-item-selected {
      background-color: rgba(255, 255, 255, 0.04);
      box-shadow: inset 3px 0 0 #886ab5;
      font-family: "NotoSansKR-Bold";
      color: #fff;
    }
  }
`;

export const SidebarFooter = styled.div`
  border-top: 1px solid #e8e8e8;
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 0 24px;
  display: flex;
  justify-content: center;

  div {
    cursor: pointer;
  }
`;
