import React from "react";
import { ToastContainer } from "react-toastify";

interface Props {
  draggable?: boolean;
}

const Toastify = (props: Props): React.ReactElement => {
  const { draggable } = props;

  return <ToastContainer draggable={draggable} autoClose={2000} />;
};

export default Toastify;
