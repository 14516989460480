import { Checkbox } from "antd";
import React from "react";

import * as S from "./style";

interface Props {
  className?: string;
  anytext?: string;
  label?: string;
  isCheck?: boolean;
  onChange?: any;
  children?: any;
}

const CheckboxItem = ({
  anytext,
  isCheck,
  onChange,
  label,
  children,
}: Props): React.ReactElement => {
  return (
    <S.Container>
      <Checkbox onChange={onChange} checked={isCheck}>
        {children}
      </Checkbox>

      {anytext && <S.AnyText checked={isCheck}>{anytext}</S.AnyText>}
    </S.Container>
  );
};

export default CheckboxItem;
