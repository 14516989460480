import { createGlobalStyle } from "styled-components";
import {
  NotoSansKrBlack,
  NotoSansKrBold,
  NotoSansKrLight,
  NotoSansKrMedium,
  NotoSansKrRegular,
  NotoSansKrThin,
} from "assets/fonts/fonts";

const GlobalStyle = createGlobalStyle`
@font-face {
      font-family: 'NotoSansKR-Thin';
      src: local('NotoSansKR-Thin'), url(${NotoSansKrThin}) format('opentype');
  }

  @font-face {
      font-family: 'NotoSansKR-Light';
      src: local('NotoSansKR-Light'), url(${NotoSansKrLight}) format('opentype');
  }

  @font-face {
      font-family: 'NotoSansKR-Regular';
      src: local('NotoSansKR-Regular'), url(${NotoSansKrRegular}) format('opentype');
  }

  @font-face {
      font-family: 'NotoSansKR-Medium';
      src: local('NotoSansKR-Medium'), url(${NotoSansKrMedium}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKR-Bold';
    src: local('NotoSansKR-Bold'), url(${NotoSansKrBold}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKR-Black';
    src: local('NotoSansKR-Black'), url(${NotoSansKrBlack}) format('opentype');
  }

  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 0.875rem;
    font-family: 'NotoSansKR-Regular', sans-serif !important;
  }
  .ant-input {
    font-family: 'NotoSansKR-Regular', sans-serif ;
  }
  #root {
    height: 100%;
  }

  a{
    &:hover{
      text-decoration: none;
    }
  }

  p,
  label {
    line-height: 1.5em;
    margin: 0;
  }
  
  .image {
    max-width: 100%;
    object-fit: cover;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 10px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    max-width: 230px;
    background-color: #584577;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(180, 185, 171, 0.3);
    background-color: #c6bad9;
  }

  .link {
    text-decoration: underline;
  }

  .text-blue {
    color: #0000ff;
  }

  .text-orange {
    color: #ff9900;
  }

  .ant-picker, .ant-select  {
    width: 100%;
  }

  .ant-picker .ant-picker-input >input, .ant-select-selection-item  {
    font-size: 16px;
    font-family: 'NotoSansKR-Regular'
  }

  .ant-select-selector {
    height: 35px !important;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  /* .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  } */

  .ant-btn >span {
    font-family: 'NotoSansKR-Regular'
  }
  .find-noti {
    width: fit-content !important;
  }
  .ant-pagination-options {
    position: absolute;
    top: 0;
    margin: 0;
    right: 200px;
  }

  .btn-default {
    background-color: #584475;
    color: #fff;
    border-color: #584475;

    &:hover {
      background-color: #584475 !important;
      color: #fff !important;
      border-color: #584475 !important;
    }
  }
`;

export default GlobalStyle;
