import styled from "styled-components";

export const Container = styled.div<{ collapse?: boolean; width?: number }>``;

export const WrapInput = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 32px;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    height: 35px;
  }

  .input-add {
    width: 354px;
  }
`;

export const WrapId = styled.div`
  display: flex;
  gap: 32px;

  &.mt-8 {
    margin-top: 8px;
  }
`;

export const ItemRecord = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon-delete {
    cursor: pointer;
  }
`;

export const Value = styled.div`
  font-size: 16px;

  &.text-black {
    color: #1677ff;
    text-decoration: underline;
    cursor: pointer;
  }
`;
