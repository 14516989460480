import styled from "styled-components";

export const Container = styled.div`
  .ant-radio-wrapper {
    font-family: "NotoSansKR-Regular";
    font-size: 16px;
    line-height: 1.371429;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #fff;
    border-color: #584475;
  }

  .ant-radio-wrapper .ant-radio-inner::after {
    background-color: #584475;
    width: 18px;
    height: 18px;
    margin-block-start: -9px;
    margin-inline-start: -9px;
  }
`;
