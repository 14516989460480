import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../redux/store";

import { User, userLogin as logInService } from "services/User";
interface UserState {
  // userid: string,
  msgCode: string | null;
  isLoading: boolean;
  // email: string,
  accessToken: string | null;
  error: object | null;
  authorName: string | null;
}

const userInitialState: UserState = {
  // userid: '',
  isLoading: false,
  msgCode: "",
  // email: '',
  accessToken: "",
  error: null,
  authorName: "",
};

function startLoading(state: UserState) {
  state.isLoading = true;
}

function loadingFailed(
  state: UserState | undefined,
  action: PayloadAction<any>
) {
  state.isLoading = false;
  state.error = action.payload;
}

function reset(state: UserState) {
  state.msgCode = null;
  state.isLoading = false;
  state.error = null;
}

const user = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    startLogIn: startLoading,
    setUser(state, { payload }: PayloadAction<User>) {
      const { accessToken, authorName } = payload;
      console.log(payload);
      state.accessToken = accessToken;
      state.isLoading = false;
      state.authorName = authorName;
    },
    logInFailure: loadingFailed,
    logOut: (_state): any => {
      return {};
    },
    resetState: reset,
  },
});

export const { setUser, startLogIn, logInFailure, logOut, resetState } =
  user.actions;

export const login =
  (formData: object): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLogIn());
      const response: any = await logInService(formData);
      if (!!response.success) {
        dispatch(setUser(response.data));
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      dispatch(logInFailure(error));
    }
  };

export default user.reducer;
