import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { Router } from "react-router";
import Web3 from "web3";
import { createBrowserHistory } from "history";
import App from "./App";
// import 'antd/dist/antd.min.css'
import "bootstrap/dist/css/bootstrap.min.css";
// import * as serviceWorker from './serviceWorker';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_kr from "./locales/translation/kr/translation.json";
import common_en from "./locales/translation/en/translation.json";

const history = createBrowserHistory();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "kr", // language to use
  resources: {
    kr: {
      common: common_kr,
    },
    en: {
      common: common_en, // 'common' is our custom namespace
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <Router history={history}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Router>
    </PersistGate>
  </Provider>
  // ,
  // document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
